import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo/Seo';
import TopBanner from '../components/topBanner/TopBanner';
import StructuredTextExtendedContent from '../components/structuredTextExtendedContent';

const SingleNestedPage = ({
	data: { allDataPort, allDataBusiness, allDataCompany, allDatoCmsMapWharfsSzczecin, allDatoCmsMapWharfsSwinoujscie },
	pageContext,
}) => {
	const nestedPageType = pageContext?.nestedPrefix || '';
	const currentDataSource =
		nestedPageType === 'allDatoCmsSinglePagesBusiness'
			? allDataBusiness
			: nestedPageType === 'allDatoCmsSinglePagesCompany'
			? allDataCompany
			: allDataPort;
	const activeSite = pageContext?.locale || '';
	const breadCrumbsData = pageContext?.breadCrumbs || [];
	const activeCrumb = pageContext?.page?.singlePageName || [];
	const seoData = currentDataSource?.nodes?.[0]?.seoSettings || '';
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || '';
	const preparedSitemapSimplify = pageContext?.preparedSitemapSimplify || [];
	const structuredTextData = currentDataSource?.nodes?.[0]?.content || '';

	const bannerData = {
		mainHeader: currentDataSource?.nodes?.[0]?.mainHeader || '',
		subheader: currentDataSource?.nodes?.[0]?.subheader || '',
		description: currentDataSource?.nodes?.[0]?.description || '',
		backgroundImage: currentDataSource?.nodes?.[0]?.backgroundImage || '',
		backgroundImageMobile: currentDataSource?.nodes?.[0]?.backgroundImageMobile || '',
	};

	const globals = pageContext?.globals?.data?.globals || '';
	return (
		<>
			<Seo seoData={seoData} seoGlobal={seoGlobal} />
			<TopBanner
				data={bannerData}
				breadCrumbsData={breadCrumbsData}
				activeCrumb={activeCrumb}
				activeSite={activeSite}
				pageContext={pageContext}
			/>
			{/* <BreadCrumbs data={breadCrumbsData} activeCrumb={singlePageName} activeSite={activeSite} /> */}
			<StructuredTextExtendedContent
				data={structuredTextData}
				allDatoCmsMapWharfsSzczecin={allDatoCmsMapWharfsSzczecin}
				allDatoCmsMapWharfsSwinoujscie={allDatoCmsMapWharfsSwinoujscie}
				preparedSitemapSimplify={preparedSitemapSimplify}
				activeSite={activeSite}
				pageContext={pageContext}
				globals={globals}
			/>
		</>
	);
};

export default SingleNestedPage;

export const query = graphql`
	query ($originalId: String, $locale: String) {
		allDataPort: allDatoCmsSinglePagesPort(filter: { locale: { eq: $locale }, originalId: { eq: $originalId } }) {
			nodes {
				originalId
				mainHeader
				subheader
				description
				backgroundImage {
					url
					alt
					gatsbyImageData
				}
				backgroundImageMobile {
					url
					alt
					gatsbyImageData
				}
				content {
					value
					__typename
					links {
						__typename
						...singlePagePortLink
						...singlePageBusinessLink
						...singlePageCompanyLink
						...singlePageLink
						...singleArticlePageLink
						...moduleLinkToPage
						...moduleGridGallery
						...moduleMapWithSinglePoint
						...moduleSeeAlso
						...formUkrainianCargo
						...mapWithShipsInPort
						...moduleTable
						...filesToDownloads
						...mapWithFixedPoints
						...moduleUnderlineText
						...moduleHeaderWithDescription
						...moduleSingleChart
						...wharfsMap
						...moduleTabContent
						...moduleTableLargeContent
						...dropdownList
						...videoMiniatures
						...moduleFormContact
						...moduleManagementTile
						...moduleTilesWithFilesToDownload
						...mapDutyFreeZone
						...photoDescriptionTemplate
					}
					blocks {
						__typename
						...blockImageLarge
						...blockImageSmall
						...blockGallery
						...blockMargin
						...alignTextBlock
					}
				}
			}
		}
		allDataBusiness: allDatoCmsSinglePagesBusiness(filter: { locale: { eq: $locale }, originalId: { eq: $originalId } }) {
			nodes {
				originalId
				mainHeader
				subheader
				description
				backgroundImage {
					url
					alt
					gatsbyImageData
				}
				backgroundImageMobile {
					url
					alt
					gatsbyImageData
				}
				content {
					value
					__typename
					links {
						__typename
						...singlePagePortLink
						...singlePageBusinessLink
						...singlePageCompanyLink
						...singlePageLink
						...singleArticlePageLink
						...moduleLinkToPage
						...moduleGridGallery
						...moduleMapWithSinglePoint
						...moduleSeeAlso
						...formUkrainianCargo
						...mapWithShipsInPort
						...moduleTable
						...filesToDownloads
						...mapWithFixedPoints
						...moduleUnderlineText
						...moduleHeaderWithDescription
						...moduleSingleChart
						...wharfsMap
						...moduleTabContent
						...moduleTableLargeContent
						...dropdownList
						...videoMiniatures
						...moduleFormContact
						...moduleManagementTile
						...moduleTilesWithFilesToDownload
						...mapDutyFreeZone
					}
					blocks {
						__typename
						...blockImageLarge
						...blockImageSmall
						...blockGallery
						...blockMargin
						...alignTextBlock
					}
				}
			}
		}
		allDataCompany: allDatoCmsSinglePagesCompany(filter: { locale: { eq: $locale }, originalId: { eq: $originalId } }) {
			nodes {
				originalId
				mainHeader
				subheader
				description
				backgroundImage {
					url
					alt
					gatsbyImageData
				}
				backgroundImageMobile {
					url
					alt
					gatsbyImageData
				}
				content {
					value
					__typename
					links {
						__typename
						...singlePagePortLink
						...singlePageBusinessLink
						...singlePageCompanyLink
						...singlePageLink
						...singleArticlePageLink
						...moduleLinkToPage
						...moduleGridGallery
						...moduleMapWithSinglePoint
						...moduleSeeAlso
						...mapWithShipsInPort
						...moduleTable
						...filesToDownloads
						...mapWithFixedPoints
						...moduleUnderlineText
						...moduleHeaderWithDescription
						...moduleSingleChart
						...wharfsMap
						...moduleTabContent
						...moduleTableLargeContent
						...dropdownList
						...videoMiniatures
						...moduleFormContact
						...moduleManagementTile
						...moduleTilesWithFilesToDownload
						...mapDutyFreeZone
					}
					blocks {
						__typename
						...blockImageLarge
						...blockImageSmall
						...blockGallery
						...blockMargin
						...alignTextBlock
					}
				}
			}
		}
		allDatoCmsMapWharfsSzczecin(filter: { locale: { eq: $locale } }, sort: { fields: number, order: ASC }) {
			nodes {
				number
				name
				length
				maxDraft
				maxDraftRange
				coordinates {
					lat
					lng
				}
			}
		}
		allDatoCmsMapWharfsSwinoujscie(filter: { locale: { eq: $locale } }, sort: { fields: number, order: ASC }) {
			nodes {
				number
				name
				length
				maxDraft
				maxDraftRange
				coordinates {
					lat
					lng
				}
			}
		}
	}
`;

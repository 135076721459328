import React, { useEffect } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import PageContainer from '../common/PageContainer';
import Typography from '../typography/Typography';
import BreadCrumbs from '../breadCrumbs/BreadCrumbs';
import getSlug from '../../utils/getSlug';

const StyledTopBannerWrapper = styled.div`
	display: flex;
	flex-direction: row;
	height: calc(4 / 5 * 100vh);
	position: relative;
	width: 100vw;
	.gatsby-image-wrapper {
		height: 100%;
		width: 100%;
	}
	&:before {
		background-image: linear-gradient(to top, transparent 30%, transparent 40%, rgba(3, 50, 96, 0.6) 100%);
		content: '';
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 1;
	}
	@media (max-width: 1366px) {
		flex-direction: column;
		height: 100vh;
	}
`;

const StyledImgWrapper = styled.div`
	display: none;
	height: 100%;
	width: calc(2 / 3 * 100%);
	@media (min-width: 768px) {
		display: flex;
	}
	&.mobile {
		display: flex;
		@media (min-width: 768px) {
			display: none;
		}
	}
	@media (max-width: 1366px) {
		width: 100%;
	}
`;

const StyledHeadersWrapper = styled.div`
	align-items: flex-start;
	background-color: var(--menu-color);
	display: flex;
	flex-direction: column;
	height: calc(4 / 5 * 100vh);
	justify-content: center;
	padding-left: ${({ wcagFontSizePlus }) => (wcagFontSizePlus ? '25px' : '50px')};
	padding-right: 50px;
	padding-top: ${({ wcagFontSizePlus }) => (wcagFontSizePlus ? 'calc(1 / 10 * 100%)' : 'calc(1 / 6 * 100%)')};
	width: ${({ wcagFontSizePlus }) => (wcagFontSizePlus ? 'calc(1.5 / 3 * 100vw)' : 'calc(1 / 3 * 100vw)')};
	z-index: 2;

	@media (min-width: 601px) and (max-width: 1366px) {
		width: 100%;
		background: linear-gradient(rgba(0, 14, 65, 0), rgba(0, 14, 65, 0.75), rgba(0, 14, 65, 0.9));
		position: absolute;
		bottom: 0;
		height: calc(3 / 5 * 100vh);
		align-items: center;
		padding: 10px;
	}
	@media (max-width: 600px) {
		align-items: flex-start;
		display: flex;
		width: 100%;
		padding: 0 25px;
		height: calc(3 / 5 * 100vh);
		position: absolute;
		bottom: 0;
		background: linear-gradient(rgba(0, 14, 65, 0), rgba(0, 14, 65, 0.75), rgba(0, 14, 65, 0.9));
	}
`;

const StyledSubheaderWrapper = styled.div`
	letter-spacing: 4.8px;
	margin-bottom: 20px;
	text-transform: uppercase;
	z-index: 2;
`;
const StyledLogoWrapper = styled(Link)`
	display: none;
	@media (max-width: 1366px) {
		align-items: center;
		display: flex;
		cursor: pointer;
		justify-content: center;
		position: absolute;
		top: 80px;
		width: 100%;
		z-index: 60;
		@media (max-width: 769px) {
			top: 30px;
			padding: 0 10px;
		}
		img {
			max-height: 80px;
			width: 100%;
			@media (max-width: 769px) {
				max-height: 60px;
			}
		}
	}
`;
const StyledDescription = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 1.125rem;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 1.875rem;
	margin-top: 35px;
	max-width: 86%;
	@media (min-width: 600px) and (max-width: 1366px) {
		text-align: center;
		margin-top: 28px;
	}
	@media (max-width: 600px) {
		text-align: left;
		margin-top: 10px;
	}
`;
const StyledBreadCrumbs = styled.div`
	bottom: 30px;
	max-width: 100%;
	position: absolute;
	@media (max-width: 1366px) {
		bottom: 10px;
		@media (max-width: 600px) {
			left: 20px;
		}
	}
`;
const StyledHeader = styled.div`
	@media (min-width: 600px) and (max-width: 1366px) {
		text-align: center;
	}
	@media (max-width: 600px) {
		text-align: left;
	}
`;

const TopBanner = ({ data, breadCrumbsData, activeCrumb, activeSite, allArticlesActiveCrumb, pageContext, wcagFontSizePlus }) => {
	const mainHeader = data?.mainHeader || '';
	const subheader = data?.subheader || '';
	const description = data?.description || '';
	const backgroundImage = getImage(data.backgroundImage);
	const mobileImg = data?.backgroundImageMobile || '';
	const backgroundPhotoMobile = getImage(mobileImg) ? getImage(mobileImg) : backgroundImage;

	const globals = pageContext?.globals?.data?.globals || '';
	const globalData = globals?.nodes?.[0] || [];
	const logoBig = globalData?.logoBig?.url || '';
	const linkToHomePage = globalData?.linkToHomepage?.slug || '';

	useEffect(() => {
		const { innerHeight } = window;
		document.documentElement.style.setProperty('--inner-height', `${innerHeight}px`);
	}, []);
	// const lastFiveChars = id.substr(-5);
	return (
		<PageContainer className="full-width">
			<StyledTopBannerWrapper>
				<StyledLogoWrapper to={`/${getSlug(linkToHomePage, activeSite)}`}>
					<img alt="port Szczecin" src={logoBig} />
				</StyledLogoWrapper>
				<StyledImgWrapper className="mobile">
					<GatsbyImage className="contrast-mode" image={backgroundPhotoMobile} alt="" />
				</StyledImgWrapper>
				<StyledImgWrapper>
					<GatsbyImage className="contrast-mode" image={backgroundImage} alt="" />
				</StyledImgWrapper>

				<StyledHeadersWrapper wcagFontSizePlus={wcagFontSizePlus}>
					<StyledSubheaderWrapper>
						<Typography variant="2" fontWeight="bold" color="green">
							{subheader}
						</Typography>
					</StyledSubheaderWrapper>
					<StyledHeader>
						<Typography variant="1" fontWeight="regular" color="white">
							{mainHeader}
						</Typography>
					</StyledHeader>
					<StyledDescription>{description}</StyledDescription>

					<StyledBreadCrumbs wcagFontSizePlus={wcagFontSizePlus}>
						<BreadCrumbs
							data={breadCrumbsData}
							activeCrumb={activeCrumb}
							activeSite={activeSite}
							allArticlesActiveCrumb={allArticlesActiveCrumb}
						/>
					</StyledBreadCrumbs>
				</StyledHeadersWrapper>
			</StyledTopBannerWrapper>
		</PageContainer>
	);
};
export default connect((state) => ({
	wcagFontSizePlus: state.wcagFontSizePlus.wcagFontSizePlus,
}))(TopBanner);
